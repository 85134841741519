const FirebaseConfig = {
  apiKey: "AIzaSyBiVuCDXhRXLEzOyLYEymVmAbgVkx54jZs",
  authDomain: "zhulian-3cf03.firebaseapp.com",
  projectId: "zhulian-3cf03",
  storageBucket: "zhulian-3cf03.appspot.com",
  messagingSenderId: "268215836116",
  appId: "1:268215836116:web:50cde8845d30b530980a41",
};

export default FirebaseConfig;
